import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Rotas } from './Rotas';

import { Button } from '@mui/material';
import './App.css';
import AppLayout from './AppLayout';
import Boundary from './Boundary';

function App() {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  const debug = window.location.hostname == "localhost"

  useEffect(() => {
    if (window.location.host.includes("urgente") && !window.location.pathname.includes("relatar"))
      window.location = "https://mappsistemas.com.br/"
  }, [])

  return <div className="App">
    {debug && <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      className="position-absolute"
      style={{
        width: "300px",
        background: "white",
        boxShadow: "0 0 10px black",
        top: "25%",
        left: visible ? 0 : "-285px",
        borderRadius: "0 1rem 1rem 0",
        padding: "1rem",
        transition: "left 0.5s ease",
        zIndex: 10000
      }}
    >
      <h5>DEBUG MODE</h5>
      <div className="d-flex flex-column gap-1">
        <Button variant="contained" style={{ fontSize: "0.5rem" }} onClick={() => navigate("/playground")}>Playground</Button>
        <Button variant="contained" style={{ fontSize: "0.5rem" }} onClick={() => navigate("/relatar")}>Relatar</Button>
        <Button variant="contained" style={{ fontSize: "0.5rem" }} onClick={() => navigate("/?dominio=mototaxionline.srv.br")}>Tela Inicial</Button>
      </div>
    </div>}
    <Boundary>
      <AppLayout>
        <Rotas />
      </AppLayout>
    </Boundary>
  </div>
}

function MouseFluid() {
  const canvasRef = useRef(null);

  // Array to store the particles
  const particles = useRef([]);

  // Object to store the mouse position
  const mouse = useRef({
    x: null,
    y: null,
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Make the canvas fill the window
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Update canvas size on resize
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    window.addEventListener('resize', handleResize);

    // Track mouse position
    const handleMouseMove = (e) => {
      mouse.current.x = e.clientX;
      mouse.current.y = e.clientY;

      // Create new particles on mouse move
      for (let i = 0; i < 5; i++) {
        particles.current.push(new Particle(mouse.current.x, mouse.current.y));
      }
    };
    window.addEventListener('mousemove', handleMouseMove);

    // Particle class
    class Particle {
      constructor(x, y) {
        this.x = x;
        this.y = y;
        this.size = Math.random() * 8 + 4;           // Random size
        this.speedX = (Math.random() - 0.5) * 2;     // Random horizontal velocity
        this.speedY = (Math.random() - 1) * 2;       // Random initial vertical velocity (negative to start “up” or “down”)
        this.color = `hsl(${Math.random() * 360}, 100%, 50%)`;
        this.gravity = 0.1;                          // Gravity value; increase for stronger gravity
      }

      update() {
        this.speedY += this.gravity;  // Apply gravity
        this.x += this.speedX;
        this.y += this.speedY;

        // Slowly shrink the particle
        this.size *= 0.98;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fillStyle = this.color;
        ctx.fill();
      }
    }

    // Animation loop
    const animate = () => {
      // Semi-transparent background for “trailing” effect
      ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Update and draw particles
      particles.current.forEach((particle, index) => {
        particle.update();
        particle.draw();

        // Remove if particle is too small or off-screen (optional)
        if (particle.size < 0.5 || particle.y - particle.size > canvas.height) {
          particles.current.splice(index, 1);
        }
      });

      requestAnimationFrame(animate);
    };

    animate();

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{ display: 'block', position: 'fixed', top: 0, left: 0, zIndex: -1 }}
    />
  );
}

export default App