import { createContext, useContext, useEffect } from "react"
import { ContextoGeral } from "./contextos/ContextoGeral"
import { MdChat } from "react-icons/md"
import { useLocation, useNavigate } from "react-router-dom"
import { Badge } from "@mui/material"
import styled from "styled-components"
import { ChatContext, ChatContextProvider } from "./contextos/ContextoChat"
import { APISEM } from "./servicos/APISEM"

export default function AppLayout({ children }) {

	return <div style={{ height: "100%" }}>
		<ChatContextProvider>
			<ChatButton />
			{children}
		</ChatContextProvider>
	</div>
}


function ChatButton() {
	const { chat, status, changeStatus } = useContext(ChatContext)
	const { dados, setDados } = useContext(ContextoGeral)

	const { pathname } = useLocation()
	const navegar = useNavigate()

	useEffect(() => {
		let pos = { bottom: 1, left: -4 }
		if (dados?.chatConfig) {
			const config = {
				status: dados.chatConfig.status == "S",
				central: dados.chatConfig.entregadorPodeIniciarChat == "S",
				cliente: dados.chatConfig.clientePodeIniciarChat == "S"
			}

			if (pathname.includes("app") && status == 1)
				if (dados?.chatConfig?.status == "S" && (config?.central || config?.cliente)) {
					switch (pathname) {
						case '/app/mensagens':
							pos = { bottom: 6, left: 0 }
							break
						case '/app/home':
							pos = { bottom: 10, left: 0 }
							break
						case '/app/chat':
							pos = { bottom: dados.bottom ?? 1, left: -4 }
							break
						default:
							pos = { bottom: 1, left: 0 }
					}
				}
		}

		setDados(p => ({ ...p, ...pos }))
	}, [pathname, status])

	useEffect(() => {
		if (dados?.token && !status) {
			const api = new APISEM(dados.dominio, dados.token)
			api.buscarConfigChat({ codProf: dados.idProf }).then(r => {
				if (r.status == "200") {
					changeStatus(1)
					setDados(p => ({ ...p, chatConfig: r.dados.dados }))
				}
			}).catch(e => {
				console.error(e)
				changeStatus(2)
			})
		}
	}, [dados])

	const counter = () => {
		if (chat && chat.salas) {
			let count = chat?.salas?.central?.unread ?? 0
			if (chat?.salas?.servicos)
				Object.keys(chat?.salas?.servicos).forEach(key => {
					if (chat?.salas?.servicos[key]?.unread)
						count += chat?.salas?.servicos[key]?.unread
				})
			return count
		} else return 0
	}

	return <div style={{
		position: "fixed",
		bottom: dados.bottom + "rem",
		left: dados.left + "rem",
		boxShadow: "0 0 10px rgba(0,0,0,0.2)",
		background: "white",
		height: "4rem",
		width: "4rem",
		borderRadius: "0 50% 50% 0",
		zIndex: 5000,
		transition: "0.5s",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}}>
		<button onClick={() => navegar("/app/chat")} style={{
			borderRadius: "50%",
			width: "3rem",
			height: "3rem",
			padding: 0,
			background: "white",
			color: "var(--primaria)"
		}}>
			<Badge badgeContent={counter()} color="error">
				<MdChat size={32} />
			</Badge>
		</button>
	</div >
}