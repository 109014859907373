export function chamarTel(tel) {
	if (tel != '-' && tel.length > 0) {
		if (window.AndroidInterface) {
			if (typeof window.AndroidInterface.openPhone === "function") {
				window.AndroidInterface.redirectLink(`https://mappsistemas.com.br/ligar.php?tel=${tel}`)
			}
		}
	} else try {
		window.AndroidInterface.showAndroidVersion("telefone indisponível")
	} catch {
		alert("telefone indisponível")
	}
}

export function chamarWhats(tel) {
	if (tel != '-' && tel.length > 0) {
		if (window.AndroidInterface) {
			if (typeof window.AndroidInterface.openPhone === "function") {
				window.AndroidInterface.redirectLink(`https://wa.me/${tel}`)
			}
		}
	} else try {
		window.AndroidInterface.showAndroidVersion("telefone indisponível")
	} catch {
		alert("telefone indisponível")
	}
}

export function chamarWhatsComMsg(tel, msg, idProf, nome) {

	// Função para validar número de telefone
	function validarTelefone(tel) {
		const regexTelefone = /^\+?[1-9]\d{1,14}$/;
		return regexTelefone.test(tel);
	}

	const mensagem = `Meu nome é ${nome}.\nMeu codigo de profissional é ${idProf}.\n${msg}`;

	if (validarTelefone(tel) && msg.trim().length > 0) {
		if (window.AndroidInterface && typeof window.AndroidInterface.redirectLink === "function") {
			window.AndroidInterface.redirectLink(`https://wa.me/${tel}?text=${encodeURIComponent(mensagem)}`);
		} else {
			alert("Interface Android não disponível");
		}
	} else {
		try {
			window.AndroidInterface.showAndroidVersion("Telefone ou mensagem inválidos");
		} catch {
			alert("Telefone ou mensagem inválidos");
		}
	}
}
