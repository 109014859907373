import API from "./../../../servicos/API";

import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Spinner } from "../../../componentes/Spinner";

import { ContextoGeral } from "../../../contextos/ContextoGeral";

import { motion } from "framer-motion";

import imgSemconexao from "../../../assets/sem-conexao.png";
import MensagemApp from "../../../servicos/config/MensagemApp";

import "swiper/css";
import "swiper/css/pagination";
import "./home.module.css";

import { MdClose, MdInfo } from "react-icons/md";
import { configAnim } from "../../../servicos/config/configAnim";

import { Backdrop, Button } from "@mui/material";
import Refresher from "../../../componentes/uteis/Refresher";
import ProfissionalAPI from "../../../servicos/ProfissionalAPI";

export function Home() {
	const [stages, setStages] = useState([])

	const [show, setShow] = useState([false, null])

	const [requesting, setRequesting] = useState(true)
	const [semDominio, setSemDominio] = useState(false)

	const [reqPaineis, setReqPaineis] = useState(true)

	const [linkTermos, setLinkTermos] = useState("")

	const { dados } = useContext(ContextoGeral)

	const [btmHeight, setBtmHeight] = useState("")

	async function termoDeUso() {
		let retorno = await ProfissionalAPI.buscarTermosUso(dados);

		if (retorno?.resultado) {
			if (retorno.dados != "" && retorno.dados.link != "") {
				setLinkTermos(retorno.dados.link);
			}
		}
	}

	async function buscarPaineis() {
		await API.post("/appProfissional/acessar", {
			dominio: dados.dominio,
			rota: "/telaInicial",
			metodo: "GET",
		})
			.then((res) => {
				if (res.data.resultado) {
					setStages(res.data.dados);
				}
			})
			.catch((rej) => {
				console.log(rej);
			});
		setReqPaineis(false);
		termoDeUso()
	}

	useEffect(() => {
		if (stages.length > 0) {
			setRequesting(false);
		}
	}, [stages]);

	useEffect(() => {
		(async function () {
			if (requesting) {
				if (dados.dominio) {
					await buscarPaineis();
				} else {
					setSemDominio(true);
				}
			}
		})();
	}, [])

	useLayoutEffect(() => {
		if (document.querySelector("#btm")) {
			let el = document.querySelector("#btm")
			setBtmHeight(el.offsetHeight)
		}
	})

	return <>
		<motion.div
			className="corpo"
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
		>
			{semDominio ? (
				<div className="d-flex align-items-center" style={{ height: "100%" }}>
					<img
						src={imgSemconexao}
						alt="você está sem conexão"
						style={{
							width: "100vw",
							marginLeft: "-0.5rem",
						}}
					/>
					<div className="position-absolute w-100 d-flex justify-content-center" style={{ bottom: "3rem" }}>
						<Refresher onClick={() => {
							if (window.AndroidInterface)
								window.AndroidInterface.reloadPage()
						}} />
					</div>
				</div>
			) : (
				<motion.div
					initial={{
						opacity: 0,
					}}
					animate={{
						opacity: 1,
					}}
					exit={{
						opacity: 0,
						x: "-125%",
					}}
					transition={{ ease: "easeInOut", duration: 0.5 }}
					style={{ height: "100%" }}
				>
					{requesting ? (
						<div className="corpo">
							<Spinner />
						</div>
					) : (
						<>
							{reqPaineis
								? <div className="h-100">
									<Spinner />
								</div>
								: <>
									{stages.length > 0
										? <Swiper
											effect={"coverflow"}
											grabCursor={true}
											centeredSlides={true}
											slidesPerView={"auto"}
											coverflowEffect={{
												rotate: 50,
												stretch: 0,
												depth: 100,
												modifier: 1,
												slideShadows: true,
											}}
											pagination={true}
											modules={[Pagination]}
										>
											{stages.length && stages.map(function (i, e) {
												return <SwiperSlide
													key={i.key}
													style={{
														paddingTop: "1rem",
														height: `calc(100vh - (${btmHeight}px + 1.5rem))`,
													}}
												>
													<div className="ajustarVertical" >
														<div className="text-center">
															<h4 style={{ fontSize: "calc(0.25rem + 3vh)" }}>{i.title}</h4>
														</div>
														<div
															className="blocoImagem"
															style={{ height: `calc(70vh - ${btmHeight}px)` }}
														>
															<img src={i.image} className="imagemPainel" alt="" />
														</div>
														<p className="textCenter small" style={{
															lineHeight: 1
														}}>{i.description}</p>
													</div>
												</SwiperSlide>
											})}
										</Swiper>
										: <div className="h-100 w-100 d-flex align-items-center">
											<div className="d-flex flex-column align-items-center gap-4 text-center p-3">
												<MdInfo size={42} />
												{MensagemApp.erroPaineis}
											</div>
										</div>}
								</>}

							<div id="btm" className="toBottom">
								<div className="fullCenter">
									<Link to="/login">
										<Button variant="contained" fullWidth>
											Entrar
										</Button>
									</Link>
								</div>
								<div className="fullCenter mt-2">
									<Link to="/cadastro">
										<Button variant="outlined" fullWidth>
											Cadastrar
										</Button>
									</Link>
								</div>
								<div className="d-flex justify-content-between mt-2">
									<Button size="small" onClick={() => setShow([true, "privacidade"])}><small>Política de privacidade</small></Button>
									{linkTermos && (
										<Button size="small" onClick={() => setShow([true, "uso"])}><small>Termos de uso</small></Button>
									)}
								</div>
							</div>
						</>
					)}
				</motion.div>
			)}
		</motion.div>
		<Backdrop open={show[0]} sx={{ height: "100vh", zIndex: 2000 }}>
			<div style={{ height: "100%", maxHeight: "100vh", width: "100%", backgroundColor: "white", zIndex: 2000 }}>
				<div className="d-flex justify-content-end position-sticky p-3">
					<MdClose size={32} onClick={() => setShow([false, null])} />
				</div>
				<iframe
					className="mb-5"
					style={{ width: "100%", height: "calc(100% - 5rem)" }}
					src={show[1] == "uso"
						? `${linkTermos}?exibirBtn=false`
						: "https://" + dados.dominio + "/politicaPrivacidadeAndroid"}
					title="política de privacidade"
				/>
			</div>
		</Backdrop>
	</>
}
