import { useContext, useEffect, useState } from "react";
import { BlocoEnviar } from "./Comp";
import { APISEM } from "../../../servicos/APISEM";
import { ChatContext } from "../../../contextos/ContextoChat";
import { ContextoGeral } from "../../../contextos/ContextoGeral";
import { Collapse, Fade, IconButton } from "@mui/material";
import { Spinner } from "../../../componentes/Spinner";
import ServicoAPI from "../../../servicos/ServicoAPI";
import { MdClose } from "react-icons/md";
import { print } from "../../../servicos/Uteis";

export function dateFormat(input) {
	const date = new Date(input)
	return String(date.getHours()).padStart(2, "0") + ":" + String(date.getMinutes()).padStart(2, "0") + " " + String(date.getDate()).padStart(2, "0") + "/" + String(date.getMonth() + 1).padStart(2, "0")
}

export function ChatServicos() {
	const { chat } = useContext(ChatContext)
	const { dados } = useContext(ContextoGeral)

	const [loading, setLoading] = useState(true)
	const [show, setShow] = useState(false)
	const [servicos, setServicos] = useState([])
	const [selecionado, setSelecionado] = useState(null)

	async function buscarServicosExecucao() {
		await ServicoAPI.buscarServicos(dados).then(r => {
			if (r.resultado && r.dados?.length > 0)
				setServicos(r.dados.map(item => {
					let unred = 0
					if (chat?.salas?.servicos) Object.keys(chat?.salas?.servicos).some(key => {
						if (key.includes(item["s.id"])) {
							unred = chat.salas.servicos[key]?.unread
							return true
						}
					})

					return {
						id: item["s.id"],
						idCliente: item["s.idSolicitante"],
						unread: unred
					}
				}))
		}).finally(r => setLoading(false))
	}

	useEffect(() => {
		setServicos(p => p.map(item => {
			const nItem = item
			if (chat?.salas?.servicos) Object.keys(chat?.salas?.servicos).some(key => {
				if (key.includes(nItem.id)) {
					nItem.unread = chat.salas.servicos[key]?.unread
					return true
				}
			})

			return nItem
		}))
	}, [chat])

	useEffect(() => {
		if (loading) buscarServicosExecucao()
	}, [loading])

	return <div style={{ height: "100%" }}>
		<Fade in={loading} style={{ height: "100%" }}>
			<div className="d-flex justify-content-center align-items-center" style={{ height: "100%", width: "100%", position: "absolute", top: 0 }}>
				<Spinner />
			</div>
		</Fade>
		<Fade in={!loading}>
			<div style={{
				height: "100%"
			}}>
				<Collapse in={!selecionado}>
					<div>
						<div className="fw700 opacity-75 text-center">Serviços disponíveis</div>
						<div>
							{servicos.map((s, i) => <button key={i} className="position-relative w-100 my-1" onClick={() => setSelecionado(s)}>
								{s.id}
								{s?.unread > 0 && <div style={{
									position: "absolute",
									top: "50%",
									transform: "translateY(-50%)",
									right: "0.5rem",
									height: "1.5rem",
									width: "1.5rem",
									borderRadius: "50%",
									background: "red"
								}}>
									{s?.unread ?? 0}
								</div>}
							</button>)}
						</div>
						{servicos.length == 0 && <div className="alert alert-info mt-3 text-center">
							Nenhum serviço disponível
						</div>}
					</div>
				</Collapse>
				<SalaServico show={selecionado != null} s={selecionado} onClose={() => setSelecionado(null)} />
			</div>
		</Fade>
	</div>
}

function SalaServico({ show, onClose, s }) {
	const { chat, connectServico } = useContext(ChatContext)

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (s?.id && s?.idCliente) {
			setLoading(true)
			connectServico(s.id, s.idCliente)
		}
	}, [s])

	useEffect(() => {
		if (loading && chat?.salas?.servicos) Object.keys(chat.salas.servicos).map(sala => {
			if (s?.id && sala.includes(s.id)) setLoading(false)
		})
	}, [chat, loading])

	return <div style={{
		height: "100%",
		width: "100%",
		position: "absolute", top: 0, zIndex: show ? 5 : -5
	}}>
		<Fade in={show} unmountOnExit>
			<div style={{ height: "100%" }}>
				{s?.id && <div style={{
					position: "relative",
					height: "100%",
				}}>
					<div style={{
						position: "absolute",
						top: "0",
						left: "0",
						display: "flex",
						alignItems: "center",
						width: "100%",
						gap: "0.5rem",
						background: "linear-gradient(0deg, #FFF0, #FFF, #FFF)",
						zIndex: 1000,
						fontWeight: 700,
					}}>
						<IconButton className="bg-danger m-3 text-white" onClick={onClose} >
							<MdClose />
						</IconButton>
						Fechar chat do serviço {s.id}
					</div>
					<ChatServicoCorpo idServico={s.id} />
				</div>}
			</div>
		</Fade>
	</div>
}

export function ChatServicoCorpo({ idServico }) {
	const { chat, socket, dispatch } = useContext(ChatContext)
	const { dados } = useContext(ContextoGeral)

	const [msg, setMsg] = useState("")
	const [msgCount, setMsgCount] = useState(0)
	const [sala, setSala] = useState("")
	const [loading, setLoading] = useState(true)
	const [plus, setPlus] = useState(true)

	async function buscarMsgsAntigas() {
		if (chat && sala) {
			const currRoom = chat?.salas?.servicos?.[sala]
			if (currRoom) {
				setLoading(true)
				const api = new APISEM(dados.dominio)

				await api.buscarMsgSala({
					idSala: currRoom.idSala,
					limite: 10,
					idBusca: currRoom?.previousID ?? null
				}).then(r => {
					if (r.status == "200") {
						const data = r.dados.dados
						if (data[data.length - 1]?.id)
							dispatch({
								type: "prevServico", sala: sala, msgs: data.map(item => {
									return {
										id: item.id,
										txt: item.mensagem,
										name: item.nome,
										outro: item.idUsuario != dados.idProf,
										enviado: true,
										recebido: true,
										date: new Date(item.timeCadastro * 1000)
									}
								}), previousID: data[data.length - 1].id
							})

						if (data.length == 0)
							setPlus(false)
					}
				}).finally(() => {
					setLoading(false)
				})
				dispatch({ type: "clearService", subType: sala })
			}
		}
	}

	function enviarMsg() {
		if (msg == "") {
			print("mensagem vazia")
			return
		}

		if (!sala) {
			print("Sala não conectada")
			return
		}

		if (socket) {
			socket.emit("sendMessageToRoom", {
				roomName: sala,
				salaId: chat?.salas?.servicos[sala].idSala,
				message: msg
			})

			dispatch({ type: "clearService", subType: sala })
			setMsg("")
		} else console.error("socket não conectado")
	}

	useEffect(() => {
		if (sala) buscarMsgsAntigas()
	}, [sala])

	useEffect(() => {
		if (sala) setMsgCount(chat?.salas?.servicos[sala]?.mensagens?.length)
		if (chat && !sala) {
			const servicos = chat?.salas?.servicos
			if (servicos) Object.keys(servicos).forEach(async key => {
				if (key.includes(idServico)) {
					setLoading(false)
					setSala(key)
				}
			})
		}
	}, [chat, sala])

	useEffect(() => {
		console.log("alterou count")
		if (msgCount > 0)
			dispatch({ type: "clearService", subType: sala })
	}, [msgCount])

	return <div style={{
		position: "relative",
		display: "flex",
		flexDirection: "column",
		height: "100%"
	}}>
		<Fade in={loading}>
			<div style={{
				background: "white",
				height: "100%",
				width: "100%",
				position: "absolute",
				zIndex: 100,
				top: 0,
			}}>
				<Spinner />
			</div>
		</Fade>
		<div style={{
			height: "100%",
			overflowY: "auto",
			display: "flex",
			flexDirection: "column-reverse"
		}}>
			{msgCount > 0
				? <>
					{sala && chat?.salas?.servicos[sala]?.mensagens?.map((m, i) => <div
						key={i}
						style={{
							background: "white",
							padding: "0.5rem",
							borderRadius: "0.5rem",
							width: "80%",
							background: m.outro ? "var(--primaria)" : "var(--primaria-brighter)",
							color: "white",
							lineHeight: 1.1,
							marginTop: "3px",
							marginBottom: "3px",
							marginRight: "0.5rem",
							marginLeft: m.outro ? "auto" : "0.5rem",
							boxShadow: "0 0 15px -7px rgba(0,0,0,1)"
						}}>
						<div style={{
							lineHeight: 1,
							fontSize: "12px",
							fontWeight: 700,
							display: "flex",
							justifyContent: "space-between"
						}}>
							<div>{m.name}</div>
							<div>{dateFormat(m.date)}</div>
						</div>
						{m.txt}
					</div>)}
					{plus
						? <button className="btn btn-info text-white" disabled={!APISEM} onClick={buscarMsgsAntigas} style={{ marginTop: "5rem", marginBottom: "1rem" }}>
							Carregar mensagens antigas
						</button>
						: <div style={{ height: "5rem", marginBottom: "5rem" }} />}
				</>
				: <div className="alert alert-info text-center">
					Ainda não há mensagens nesta conversa
				</div>}
		</div>
		<BlocoEnviar
			loading={loading}
			msg={msg}
			onChange={e => setMsg(e.target.value)}
			onSend={enviarMsg}
			disabled={loading}
		/>
	</div>
}