import { createContext } from 'react'
import useChat from '../hooks/chat/useChat'

export const ChatContext = createContext({})

export function ChatContextProvider({ children }) {
	const { socket, chat, dispatch, connectCentral, connectServico, status, changeStatus } = useChat()

	return <ChatContext.Provider value={{ socket, chat, dispatch, connectCentral, connectServico, status, changeStatus }}>
		{children}
	</ChatContext.Provider>
}