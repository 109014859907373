import { MdSend } from "react-icons/md";
import { Spinner } from "../../../componentes/Spinner";
import { IconButton } from "@mui/material";

export function BlocoEnviar({ loading, msg, onChange, onSend, disabled }) {
	return <div style={{
		height: "5rem",
		display: "flex",
		alignItems: "center",
		borderRadius: "1rem",
		padding: "0.5rem",
		margin: "0.5rem",
		boxShadow: "0 0 15px -7px rgba(0,0,0,1)",
		position: "sticky",
		bottom: 0,
		zIndex: 1000,
		background: "white"
	}}>
		{loading && <div className="w-100 h-100 d-flex justify-content-center align-items-top" style={{ zIndex: 1000, background: "rgba(255,255,255,0.5)" }}>
			<Spinner />
		</div>}
		<textarea value={msg} onChange={onChange} style={{
			resize: "none",
			background: "transparent",
			height: "100%",
			width: "calc(100% - 2.5rem)",
			lineHeight: 1,
			border: "none"
		}} disabled={loading} />
		<div className="d-flex justify-content-center align-items-center">
			<IconButton
				onClick={onSend}
				disabled={disabled}
			>
				<MdSend />
			</IconButton>
		</div>
	</div>
}