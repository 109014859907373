import { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import { MdVisibility, MdArrowForward, MdVisibilityOff } from "react-icons/md"
import { IntlProvider, FormattedNumber } from "react-intl"

import styled from "styled-components"
import ProfissionalAPI from "../../servicos/ProfissionalAPI"
import { ContextoGeral } from "../../contextos/ContextoGeral"
import AppAPI from "../../servicos/AppAPI"
import APISemNode from "../../servicos/APISemNode"
import { Collapse } from "@mui/material"
import { chamarWhatsComMsg } from "./../uteis/telefone"

const Div = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
`

const BlocoSaldo = styled.div`
    width: 50%;
    margin: 0rem 0.3rem;
    display: flex
`

const Saldo = styled.div`
    height: 1.5rem;
    background-color: #00000033;
    padding: 0.2rem;
    border-radius: 0.25rem;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 0.8rem;
    font-weight: 500;
    width: 100%;

    ${({ estado }) => estado && `
        box-shadow: inset 0px 2px 6px rgba(0,0,0,12%);
        background-color: #ffffffff;
    `}
`

const Label = styled.div`
    position: absolute;
    width: 50%;
    top: -1rem;
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
`

const BlocoPlific = styled.div`
    width: 100%;
    margin: 0rem 0.3rem;
    margin-top: 1rem;
    text-align: center;;
`

const BtnPlific = styled.div`
    background-color: #100940;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
    outline: none;
`

export default function BtnSaldo(props) {
    const navigate = useNavigate();

    const [estado, setEstado] = useState(false)
    const [show, setShow] = useState(false)
    const [showArrow, setShowArrow] = useState(false)
    const { dados } = useContext(ContextoGeral)
    const [saldo, setSaldo] = useState(0)
    const [plific, setPlific] = useState([])

    async function buscarSaldo() {
        let jsonConfig = await AppAPI.buscarConfigRelatorio(dados)
        if (jsonConfig.resultado && jsonConfig.dados.campos) {
            setShowArrow(jsonConfig.dados.campos.acertos.exibicao)
        }

        let retornoSaldo = await ProfissionalAPI.buscarSaldo(dados)

        if (retornoSaldo.resultado) {
            setSaldo(retornoSaldo.dados.saldo)
            setShow(retornoSaldo.dados.exibicao)
            setPlific(retornoSaldo.dados.plific)
        } else {
            setSaldo(0)
        }
    }

    useEffect(() => {
        buscarSaldo()
    }, [])

    return <Collapse in={show}>
        <Div>
            {estado
                ? <MdVisibilityOff size={25} onClick={() => { setEstado(false) }} />
                : <MdVisibility size={25} onClick={() => { setEstado(true) }} />
            }
            <BlocoSaldo>
                <Label>Saldo</Label>
                <Saldo estado={estado}>
                    <IntlProvider locale="pt-BR">
                        {estado ? (
                            <FormattedNumber value={saldo} style="currency" currency="BRL" />
                        ) : (
                            ''
                        )}
                    </IntlProvider>
                </Saldo>
                {showArrow && <MdArrowForward size={25} onClick={() => navigate("/app/relatorios", { state: { tab: "C" } })} />}
            </BlocoSaldo>
            <BlocoPlific>
                {plific?.mostrarBotao == 'S' ?
                    <BtnPlific onClick={() => { chamarWhatsComMsg(plific.whatsApp, plific.mensagem, dados.idProf, dados.nome) }}>
                        {plific?.nomeBotao ? plific?.nomeBotao : 'Pedir adiantamento'}
                    </BtnPlific>
                    : ''
                }
            </BlocoPlific>
        </Div>
    </Collapse>
}