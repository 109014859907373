import axios from "axios"

export class APISEM {
	constructor(dom, token = "") {
		this.dom = dom
		this.baseURL = "https://" + dom + "/sem/v1/rotas.php"
		this.token = token
		// if (window.location?.port == "3000")
		// 	this.baseURL = "http://localhost:8080/sem/v1/rotas.php"
	}

	buscarMsgSala = params => axios.get(this.baseURL + "/modulo-chat-socket.php/buscarMensagensPorSala", {
		params: params,
		headers: {
			Authorization: "Bearer 899d24e49c7de5fa2e8245be1f5f0e9831c888bd"
		}
	}).then(r => r.data).catch(r => r.data)

	buscarConfigChat = params => axios.get(this.baseURL + "/modulo-chat-profissional/buscarConfiguracaoPorIdConfig", {
		params: params,
		headers: { Authorization: "Bearer " + this?.token }
	}).then(r => r.data).catch(r => r.data)
}